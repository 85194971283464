.controller {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  height: 36px;
  padding: 2px;
  cursor: pointer;
  background: #ebeef0;
  border-radius: 9.818px;

  .box {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 7.2px 6.4px;
    background: rgba(255, 255, 255, 0);
    border-radius: 8px;
    transition: all 0.2s ease;

    svg {
      rect {
        stroke: #c3c7cb;
      }
    }
  }

  .active {
    background: #fff;

    svg {
      rect {
        stroke: #363636;
      }
    }
  }
}
