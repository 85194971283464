@import 'styles/variable';
@import 'styles/mixins';

$paddingLeftWidth: 600px;
$maxContentWidth: 1136px;
$contentWidth: 894px;

.titleContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 0 28px;
  font-family: 'Product Sans';
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.02em;
  background-image: linear-gradient(272deg, #26bcf7, #367bff);

  h1 {
    z-index: 8;
    margin-top: 40px;
    margin-bottom: 0;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;

    @include sm {
      font-size: 32px;

      // padding-top: 58px;
    }
  }
}



.contentWrap {
  background: #fcfcfc;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: $maxContentWidth;
  margin: 0 auto;

  .table {
    width: $contentWidth;
    margin-top: 20px;
    margin-right: 16px;
    background: transparent;

    @include sm {
      width: 100%;
      margin-left: 0;
    }
  }

  .rightContent {
    width: 254px;
    height: 100%;
    min-height: 600px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0;

    @include sm {
      display: none;
    }
  }
}

.searchWrap {
  // background: #ffffff;
}

.searchForm {
  --box-shadow: none;

  box-sizing: border-box;

  // height: 148px;
  // max-width: 100%;
  display: flex;

  // position: sticky;
  // top: 0px;
  // flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 30px 0;
  padding-left: 0;

  // margin: 0 auto;
  background: #f0f4f7;

  // background: #fff;
  box-shadow: var(--box-shadow);
  transition: all ease-in 0.5s;

  &.isFixed {
    --box-shadow: 0px 0px 20px #bcbcbc;

    padding: 16px 0;
    background: #fff;

    :global {
      .MuiOutlinedInput-notchedOutline {
        border-color: #e5e6eb;
        border-width: 1px;
        border-radius: 8px 0 0 8px;
      }
    }
  }

  @include sm {
    display: none;
  }

  .searchAreaContent {
    width: calc(50vw + $paddingLeftWidth);
    max-width: $maxContentWidth;
    height: 108px;
    padding: 0;
    margin: 0 auto;

    @extend .GlobalNextAppContainer;
  }

  .searchArea {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    max-width: $maxContentWidth;

    // width: fit-content;
    // width: calc(50vw + $paddingLeftWidth);
    height: 60px;
    overflow: visible;

    // margin-top: 30px;

    &Left {
      display: flex;

      // width: $contentWidth;
    }

    &Right {
      display: flex;
    }

    .location {
      position: relative;
      width: 160px;
      height: 60px;
      padding-right: 6px;
      background: #fff;
      border: 1px solid #e5e6eb;
      border-radius: 8px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);

      :global {
        .MuiInputLabel-root {
          display: none;
        }

        .MuiOutlinedInput-root {
          height: 60px;
          overflow: hidden;
          background: #fff;
          border-radius: 10px;
        }

        // .MuiOutlinedInput-input {
        //   font-size: 16px;
        // }
      }
    }

    .searchAreaBox {
      display: flex;
      margin-left: 12px;
      border-radius: 8px 0 0 8px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);

      :global {
        .MuiOutlinedInput-root {
          height: 60px;
          background-color: #fff;
          border-radius: 8px 0 0 8px;
        }

        .MuiOutlinedInput-input {
          font-size: 16px;
        }

        .MuiInputLabel-root {
          top: 12px;
        }

        .MuiFormLabel-filled,
        .Mui-focused {
          top: 0;
          border-radius: 8px 0 0 8px;
        }

        .MuiOutlinedInput-notchedOutline {
          border-radius: 8px 0 0 8px;

          // border-width: 1px;
          // border-color: #E5E6EB;
        }

        .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
          border-color: #1976d2;
          border-width: 2px;
        }
      }
    }

    .search {

      // background: #fff;
      position: relative;
      width: 560px;
      height: 60px;
      border-radius: 0 8px 8px 0;

      // overflow: hidden;
    }

    .searchButton {
      width: 127px;
      height: 60px !important;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      color: #fff;
      text-transform: capitalize !important;
      letter-spacing: 0.15px;
      background: #136fd3 !important;
      border-radius: 0 7px 7px 0 !important;

      &:hover {
        background: #1854a3 !important;
      }
    }

    .downloadApp {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 36px;
      margin-bottom: 3px;
      margin-left: 30px;
      cursor: pointer;

      &:hover {
        .popver {
          display: block;
        }
      }

      &_phoneIconBorderPath,
      &_phoneIconHomePath {
        transition: all 0.2s ease;
      }

      .text {
        width: 116px;
        margin-left: 10px;
        font-family: 'Product Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        color: #136fd3;
        letter-spacing: 0.0075em;
      }

      .popver {
        position: absolute;
        top: 50px;
        left: -12px;
        z-index: 100;
        display: none;
        padding: 14px 20px;
        background: #fff;
        border: 0.5px solid #bcbcbc;
        border-radius: 10px;
        box-shadow: 0 0 14px #bcbcbc;

        &::after {
          position: absolute;
          top: -16px;
          left: 20px;
          width: 0;
          height: 0;
          content: ' ';
          border-color: transparent transparent #fff;
          border-style: solid;
          border-width: 8px;
        }

        img {
          width: 104px;
          height: 104px;
        }

        p {
          padding: 6px 0 0;
          margin: 0;
          line-height: 20px;
          text-align: center;
        }
      }
    }

    .downloadApp:hover .downloadApp_phoneIconBorderPath {
      fill: #136fd3;
    }

    .downloadApp:hover .downloadApp_phoneIconHomePath {
      stroke: white;
    }

    .loginButton {
      align-items: center;
      width: 216px;
      height: 44px;
      margin-left: 9px;
      font-family: 'Product Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      color: #136fd3;
      text-transform: inherit;
      letter-spacing: 0.0075em;
      border: 1.5px solid #136fd3;
      border-radius: 10px;

      span {
        margin-left: 5px;
      }
    }
  }

  .filters {
    display: flex;
    flex-direction: row;

    // justify-content: space-between;
    // width: calc(50vw + $paddingLeftWidth);
    max-width: $maxContentWidth;
    height: 50px;
    padding-top: 16px;

    // padding-left: 24px;
    // padding-right: 24px;

    &Left {
      display: flex;

      // width: $contentWidth;
    }

    &Right {
      display: flex;
      margin-left: 22px;
    }

    .filterItems {
      min-width: 128px;
      height: 32px;
      margin-right: 19px;
      background: #fff;
      border: 1px solid #e5e6eb;
      border-radius: 4px;

      :global {
        .MuiOutlinedInput-root {
          background-color: #fff !important;
        }

        .MuiOutlinedInput-input {
          background-color: #fff !important;
        }
      }
    }

    .jobFunction {
      min-width: 152px;
    }

    .jobSalary {
      min-width: 128px;
    }

    .moreButton {
      min-width: 140px;
      padding: 0 12px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: #707070;
      text-transform: inherit;
      letter-spacing: 0.28px;
      background-color: #fff;
      border: 1px solid #e5e6eb;
      border-radius: 4px;
    }

    .moreButtonCount {
      color: #136fd3;
    }

    .clearButton {
      align-items: center;
      margin-left: 2px;
      font-family: 'Product Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      color: #707070;
      text-transform: inherit;
      letter-spacing: 0.0075em;
    }
  }
}

.searchFormMoblie {

  // position: sticky;
  top: 0;
  box-sizing: border-box;
  display: none;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  max-width: 100%;
  padding: 24px 16px 14px;
  background: #f0f4f7;

  @extend .GlobalNextAppContainer;

  @include sm {
    display: flex;
    padding: 14px 14px 4px;
  }

  .searchArea {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    // margin-top: 24px;
    // padding: 0px 19px;

    justify-content: flex-start;
    width: 100%;
    background: #fff;

    // height: 74px;
    border: 1px solid #e5e6eb;
    border-radius: 4px;

    :global {
      .MuiOutlinedInput-root {
        height: 44px;
        background-color: #fff;
        border-radius: 4px 0 0 4px;
      }

      .MuiInputLabel-root {
        top: 3px;
      }

      .MuiOutlinedInput-notchedOutline {
        border-width: 0 !important;
        border-radius: 0 !important;
      }
    }

    .search {

      // margin-left: 8px;
      position: relative;
      top: 0;
      flex: 1;
      height: 44px;
      background: #fff;
    }

    .searchButton {
      padding: 4px 10px;
      margin: auto 8px auto 0;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #fff;
      text-transform: capitalize;
      letter-spacing: 0.098px;
      background: #2378e5;
      border: 0;
      border-radius: 4px;
      outline: none;
    }
  }

  .filters {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;

    // height: 155px;
    // padding: 0px 19px;
    margin-top: 32px;

    .location {
      width: 48%;
      height: 32px;
      margin-bottom: 10px;
      background: #fff;
      border: 1px solid #e5e6eb;
      border-radius: 4px;

      :global {
        .MuiOutlinedInput-root {
          background-color: #fff;
        }

        .MuiOutlinedInput-input {
          color: rgba(0, 0, 0, 0.6) !important;
          text-align: left !important;
        }
      }
    }

    .filterItems {
      width: 48%;
      height: 32px;
      margin-bottom: 10px;
      background: #fff;
      border: 1px solid #e5e6eb;

      // margin-right: 2px;
      border-radius: 4px;

      &:last-child {
        width: 100%;
      }

      :global {
        .MuiOutlinedInput-root {
          background-color: #fff !important;
        }

        .MuiOutlinedInput-input {
          background-color: #fff !important;
        }
      }
    }

    .jobFunctions {
      width: 48%;
    }

    .moreButton {
      width: 145px;
      text-transform: capitalize !important;
      border-radius: 10px;
    }

    .clearButton {
      align-items: center;
      font-family: 'Product Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      color: #707070;
      text-transform: capitalize !important;
      letter-spacing: 0.0075em;
    }
  }
}

.arrowContainer {
  .uploadResumeButton_button {
    padding: 6px 35px !important;
    background-repeat: no-repeat !important;
    background-position-x: 6px !important;
    background-position-y: 7px !important;
  }

  @include msd {
    padding: 10px !important;

    .uploadResumeButton_button {
      padding: 14px 35px !important;
      font-size: 16px !important;
    }
  }
}

.jobCard {
  position: relative;
  width: 100%;
  min-height: 166px;
  padding-right: 0;

  .urgent {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px 3px 2px;
    margin-right: 8px;
    font-family: 'Product Sans';
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    color: #fffefe;
    letter-spacing: 0.075px;
    background: linear-gradient(128deg, #f64a88 0%, #f56a6a 100%);
    border-radius: 4px;
    box-shadow: 0 4px 13px 0 rgba(255, 60, 60, 0.1);
  }

  .remote {
    background: linear-gradient(101deg, orange 6.03%, #ffcd7e 92.76%);
  }

  .container {
    position: relative;
    width: calc(100%);
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    background: #fff;
    border: 1px solid #e5e6eb;
    border-radius: 8px;
    transition: box-shadow 0.3s ease-in;

    &:hover {
      border: 1px solid #579bf5;
      box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);

      .bottomContainer {
        &::before {
          background: linear-gradient(75deg, #dff6ff 0%, #fff 100%);
        }
      }
    }

    .topContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 16px 13px 16px 24px;

      .left {
        width: 476px;
        height: 100%;

        .titleContainer {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          width: 100%;
          height: 23px;

          .title {
            --color: #1d2129;

            flex: 1;
            overflow: hidden;
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            color: var(--color);
            text-overflow: ellipsis;
            letter-spacing: 0.128px;
            white-space: nowrap;

            &:hover {
              --color: #136fd3;
            }

            p,
            h3 {
              margin-bottom: 0;
              overflow: hidden;
              font-size: 17px;
              font-style: normal;
              font-weight: 700;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .salary {
          width: fit-content;
          padding-top: 6px;
          margin-right: 10px;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 27px;
          color: #2378e5;
          text-align: right;
          letter-spacing: 0.113px;
        }

        .labelContainer {
          display: flex;
          flex-flow: row wrap;
          width: 100%;
          height: 30px;
          margin-top: 6px;
          overflow: hidden;

          .label {
            display: flex;
            align-items: flex-start;
            padding: 5px 10px;
            margin-right: 8px;
            margin-bottom: 6px;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: #4e5969;
            background: #f7f8fa;
            border-radius: 6px;
          }

        }

        .recruiterContainer {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          margin-top: 6px;

          .imgContainer {
            position: relative;
            overflow: visible;

            img {
              border: 1px solid #e5e6eb;
            }

            .isOnline {
              position: absolute;
              right: 4px;
              bottom: -2px;
              width: 9px;
              height: 9px;
              background: #0ebd5c;
              border: #fff solid 1px;
              border-radius: 4.5px;
            }
          }

          .recruiter {
            .info {
              max-width: 338px;
              height: 24px;
              padding: 2px 0;
              margin-left: 4px;
              overflow: hidden;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              color: #4e5969;
              text-overflow: ellipsis;
              letter-spacing: 0.09px;
              white-space: nowrap;

              &.isHover {
                display: none;
              }
            }

            .button {
              position: relative;
              display: none;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              height: auto !important;
              padding: 4px 16px;
              margin-top: -1px;
              margin-left: 4px;

              // line-height: 25px;
              text-transform: capitalize;
              background: #2378e5 !important;
              border-radius: 4px;
              box-shadow: 0 4px 10px 0 rgba(35, 120, 229, 0.15);

              &::after {
                position: absolute;
                top: 8px;
                left: -3px;
                width: 8px;
                height: 8px;
                content: '';
                background: #2378e5;
                transform: rotate(45deg);
              }

              &.isHover {
                display: flex;
                height: 25px !important;
                cursor: pointer;
              }

              :global {
                .MuiCircularProgress-root {
                  color: #fff;
                }
              }
            }

            // height: 21px;
          }

          .online {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 25px;
            padding: 7px 8px;
            margin-left: 8px;
            font-size: 12px;
            line-height: 25px;
            color: #4caf50;
            background: #f2f9f3;
            border-radius: 4px;

            &::before {
              width: 6px;
              height: 6px;
              margin-right: 4px;
              content: '';
              background: #4caf50;
              border: 1px solid #4caf50;
              border-radius: 3px;
            }

            :first-of-type {
              display: flex;
              align-items: center;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 15px;
              color: #fff;
              letter-spacing: 0.0075em;
            }
          }
        }
      }

      .right {
        width: 280px;
        // background: #0EBD5C;
        height: 100%;

        .company {
          // height: 23px;
          display: flex;
          flex-direction: row;
          width: 280px;

          .logo {
            margin-right: 12px;
            overflow: hidden;
            border: 1px solid #e5e6eb;
            border-radius: 4px;
          }

          .labelContainer {
            flex: 1;
            margin-top: -2px;

            .name {
              display: -webkit-box;
              width: 206px;
              overflow: hidden;
              font-family: "Product Sans";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 23px;
              color: #1d2129;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              letter-spacing: 0.32px;
              -webkit-box-orient: vertical;

              &:hover {
                color: #136fd3;
              }
            }

            .componylabels {
              display: flex;
              flex-flow: row wrap;
              justify-content: flex-start;
              width: 100%;
              margin-top: 8px;

              // max-height: 62px;
              overflow: hidden;

              .label {
                display: flex;
                align-items: flex-start;
                padding: 5px 10px;
                margin-right: 8px;
                margin-bottom: 8px;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: #4e5969;
                background: #f7f8fa;
                border-radius: 6px;

                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }

    .bottomContainer {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 38px;
      padding-right: 16px;
      padding-left: 24px;
      border-radius: 10px 0;

      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        content: '';
        background: linear-gradient(75deg, #f4faff 0%, #fff 100%);
        opacity: 1;

        // background: linear-gradient(90deg, #F2F9FF 0%, #FFFFFF 100%);
      }

      .skills {
        z-index: 10;
        width: 478px;
        height: 15px;
        overflow: hidden;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        color: #4e5969;
        text-overflow: ellipsis;
        letter-spacing: 0.09px;
        white-space: nowrap;
      }

      .benefits {
        z-index: 10;
        width: 280px;
        height: 15px;
        margin-left: 48px;
        overflow: hidden;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        color: #4e5969;
        text-overflow: ellipsis;
        letter-spacing: 0.09px;
        white-space: nowrap;
      }
    }
  }

  .popupDetail {
    // --opacity:1;
    position: absolute;
    top: 0;
    right: 30px;

    // opacity: var(--opacity);
    // transition: opacity 1 0.3s;
    // box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    z-index: 21;
    width: 380px;
    overflow: hidden;

    // height: 395px;
    background: #fff;
    border-radius: 15px;
    box-shadow: 10px 10px 10px #ddd;

    &.hide {
      display: none;

      // --opacity: 0;
    }

    .popTop {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      height: 115px;
      padding: 13px 16px;
      overflow: hidden;
      background: linear-gradient(354deg, #fff 0%, #b7dbff 100%);

      &Left {
        flex: 1;

        .title {
          align-items: center;
          width: 260px;
          overflow: hidden;
          font-family: 'Product Sans';
          font-size: 18px;
          font-style: normal;
          font-weight: bold;
          line-height: 23px;
          color: #353535;
          text-overflow: ellipsis;
          letter-spacing: 0.02em;
          white-space: nowrap;
        }

        .secondLabel {
          width: 260px;
          margin-top: 8px;
          overflow: hidden;
          font-family: 'Product Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 15px;
          color: #707070;
          text-overflow: ellipsis;
          letter-spacing: 0.0075em;
          white-space: nowrap;
        }

        .save {
          width: 126px;
          height: 30px;
          margin-top: 15px;
          color: #136fd3;
          background: transparent;
          border: 1px solid #136fd3;
          border-radius: 4px;
          box-shadow: none;
        }
      }

      &Right {
        // flex: 0;
        width: 72px;
        height: 93px;
        padding: 6px 0;
        font-family: 'Product Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        color: #136fd3;
        text-align: center;
        letter-spacing: 0.0075em;
        background: #fff;
      }
    }

    .popContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 14px 16px;

      .desTitle {
        align-self: flex-start;
        font-size: 14px;
        font-style: normal;
        font-weight: bold;
        line-height: 18px;
        color: #353535;
        letter-spacing: 0.0075em;
      }

      .detail::-webkit-scrollbar {
        width: 5px;
      }

      .detail::-webkit-scrollbar-thumb {
        background-color: #e4e4e4;
        border-radius: 5px;
      }

      .detail::-webkit-scrollbar-track {
        background: white;
        border-radius: 5px;
      }

      .detail {
        align-self: flex-start;
        width: 100%;
        max-height: 213px;
        margin-top: 8px;
        overflow: auto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        color: #353535;
        letter-spacing: 0.02em;
      }
    }
  }
}

.jobCardMoblie {
  position: relative;
  width: calc(100vw - 20px);
  margin: 0 10px;
  margin-bottom: 10px;

  .container {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 16px;
    overflow: hidden;
    cursor: pointer;
    background: #fff;
    border: 1px solid #f2f3f5;
    border-radius: 12px;
    transition: box-shadow 0.3s ease-in;

    &:hover {
      box-shadow: 0 12px 30px rgb(0, 0, 0, 0.1);
    }

    .titleContainer {

      // height: 23px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      width: 100%;

      .urgent {
        display: inline-block;
        align-items: center;
        justify-content: center;
        padding: 4px 4px 4px 3px;
        margin-right: 4px;
        font-family: 'Product Sans';
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        color: #fffefe;
        letter-spacing: 0.075px;
        vertical-align: top;
        background: linear-gradient(128deg, #f64a88 0%, #f56a6a 100%);
        border-radius: 4px;
        box-shadow: 0 4px 13px 0 rgba(255, 60, 60, 0.1);
      }

      .remote {
        background: linear-gradient(101deg, orange 6.03%, #ffcd7e 92.76%);
      }

      .title {
        display: -webkit-box;
        flex-shrink: 1;
        width: 70%;
        max-height: 48px;
        overflow: hidden;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        letter-spacing: 0.128px;

        /*! autoprefixer: off */
        -webkit-box-orient: vertical;

        /* autoprefixer: on */
      }

      .salary {
        width: 30%;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        color: #2378e5;
        text-align: right;
        letter-spacing: 0.113px;
      }
    }

    .titleContainerUrgent {
      justify-content: start;
      padding-bottom: 8px;
    }

    .companyInfo {
      display: flex;
      align-items: center;
      margin-top: 8px;
      margin-bottom: 4px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: #4e5969;
      letter-spacing: 0.28px;
    }

    .companyName {
      width: fit-content;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .companySize {
      width: fit-content;
      white-space: nowrap;
    }

    .companyInfoSpread {
      display: inline-block;
      width: 0;
      height: 12px;
      margin: 0 10px;
      vertical-align: bottom;
      border-left: 1px solid #e5e6eb;
    }

    .labelContainer {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      margin-bottom: 12px;

      .label {
        display: flex;
        align-items: flex-start;
        padding: 5px 10px;
        margin-top: 8px;
        margin-right: 8px;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #4e5969;
        background: #f7f8fa;
        border-radius: 4px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .recruiterLine {
      display: block;
      width: 100%;
      height: 1px;
      margin: 16px 0;
      background: #f2f3f5;
    }

    .recruiterContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 4px;

      .info {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: fit-content;
        width: 0;
        height: 24px;
        padding: 2px 0;
        font-family: 'Product Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        color: #353535;
        letter-spacing: 0.0075em;

        .hrTitle {
          display: block;
          max-width: 300px;
          overflow: hidden;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: #4e5969;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .fullName {
        display: block;
        width: fit-content;
        margin-left: 10px;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        color: #86909c;
        text-align: right;
        text-overflow: ellipsis;
        letter-spacing: 0.09px;
        white-space: nowrap;
      }

      .button {
        display: none;
        width: fit-content;
        height: 24px;
        padding: 4px 9px;
        font-family: 'Product Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        color: #fff;
        letter-spacing: 0.0075em;
        background: #136fd3;
        border-radius: 12px;

        &.isHover {
          display: flex;
          height: 24px;
          cursor: pointer;
        }
      }

      // height: 21px;

      .online {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        padding: 5px 8px;
        margin-left: 8px;
        font-size: 12px;
        color: #4caf50;
        background: #f2f9f3;
        border-radius: 15px;

        &::before {
          width: 8px;
          height: 8px;
          margin-right: 3px;
          content: '';
          background: #4caf50;
          border: 1px solid #fff;
          border-radius: 4px;
        }

        :first-of-type {
          display: flex;
          align-items: center;
          font-family: 'Product Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 15px;
          color: #fff;
          letter-spacing: 0.0075em;
        }
      }
    }
  }
}

.loadMore {

  // height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 15px auto 35px;
}

.viewMore {
  height: 40px;
  padding: 0 30px;
  color: rgb(35, 120, 229);
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(35, 120, 229);
  border-radius: 4px;
}

.histories {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 257px;
  background: #fff;
  border: 1px solid #e5e6eb;
  border-radius: 8px;

  .title {
    width: 100%;
    padding: 20px 16px;
    font-family: "Product Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    color: #1d2129;
    letter-spacing: 0.15px;
    border-bottom: 1px solid #e5e6eb;
  }

  &List {
    width: 100%;
    padding-top: 12px;
    padding-bottom: 20px;
  }

  a {
    display: block;
  }

  .item {
    --color: #86909c;

    width: 100%;
    padding: 8px 8px 8px 28px;
    overflow: hidden;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    color: var(--color);
    text-overflow: ellipsis;
    letter-spacing: 0.105px;
    white-space: nowrap;

    &:hover {
      --color: #1d2129;

      background: #f4f9ff;
    }
  }
}

.tableContainer {
  width: fit-content;
  width: 100%;
  padding-bottom: 50px;
  padding-left: 0;
  background: transparent;
  border-radius: 10px;

  // height: 100%;
  // margin-left: 24px;

  @include sm {
    display: none;
  }

  //   @include sm {
  //     display: flex;
  //     flex-direction: column;
  //     height: auto;
  //   }

  .jobContainer {
    margin-bottom: 15px;
  }
}

.mobileContainer {
  display: none;

  @include sm {
    display: flex;
    flex-direction: column;
  }
}

.loadingSke {
  width: 100%;
  height: 145px;
  padding: 14px 24px 0;
  margin-bottom: 16px;
  margin-left: 0;
  background: #fff;
  border-radius: 10px;

  .top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.qrcode {
  transition: transform 0.4s ease;
  transform: scale(1);
}

.qrcode:hover {
  transform: scale(1.5);
}

.arrowIconPostion {
  margin-bottom: 16px;
}

.qrCode {
  @include sm {
    display: none;
  }

  position: fixed;
  top: 254px;
  right: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  width: 118px;
  padding: 10px;

  // height: 164px;
  margin-bottom: 16px;
  background: #fff;
  border-radius: 8px 0 0 8px;
  box-shadow: 0 0 41px 0 rgba(0, 0, 0, 0.1);

  &Text {
    display: flex;
    flex-direction: column;
    font-family: "Product Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #2378e5;
    text-align: center;
    letter-spacing: 0.105px;
  }
}

.tablePagination {
  margin-top: 40px;
}

.uploadResumeButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 257px;
  padding: 16px 8px;
  background: linear-gradient(142deg, #edfbff 0%, #f3f8ff 100%);
  border: 1px solid #92c2ff;
  border-radius: 8px;

  &Image {
    margin-left: -32px;
  }

  &Main {
    display: flex;
  }

  &Right {
    display: flex;
    flex-direction: column;
  }

  &Text1 {
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    color: #1d2129;
    letter-spacing: 0.128px;
  }

  &Text2 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    color: #4e5969;
    letter-spacing: 0.105px;
  }

  &Upload {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: 190px;
    padding: 8px 16px;
    background: #fff;
    border: 1px solid #2378e5;
    border-radius: 8px;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
  }
}

.chatButtonText {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  color: #fff;
  text-transform: none;
  letter-spacing: 0.09px;
}

.hotjobTitle {
  height: 20px;
  margin-bottom: 50px;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  border-bottom: 1px solid #e4e4e4;

  span {
    display: inline-block;
    padding: 0 20px;
    background: #fcfcfc;
  }
}