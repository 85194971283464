.pagination {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;

  .paginationItemBase {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    min-height: 32px;
    max-height: 32px;
    padding: 4px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 10px;
  }

  .paginationItemNumber {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: #000;
    text-align: center;
    background: #fff;
    border: 1px solid #ebeef0;
    box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);

    &[data-checked="true"] {
      color: #fff;
      background-color: #1c70ee;
      border-color: #1c70ee;
    }

    &[data-checked="false"]:hover {
      color: #1c70ee;
      border: 1px solid #1c70ee; // var(--brand-color-052378-e-5, #);
    }
  }

  .actionBtn {
    padding: 4px;
    cursor: pointer;

    &.prePageBtn {
      svg {
        transform: rotate(180deg);
      }
    }

    border: none;

    &[data-disabled="false"]:hover {
      background-color: #e8e8ea;
    }

    &[data-disabled="true"] {
      // color: #fff;
      // background-color: #555;
      cursor: not-allowed;

      svg path {
        fill: #b8b8b8;
      }
    }
  }
}
